import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import axiosPublic from "../../config/Public";
import { useParams } from "react-router-dom";


const CmsData = () => {
  const [data, setData] = useState("");
  var param = useParams();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    axiosPublic
        .get("/admin/cms/detail?type="+param.type)
        .then((response) => {
            setData(response.data.responseData.cms.description);
            setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }, []);


  return (
    <>
    { loader ? <div style={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)'}}>
        <CircularProgress size={100}  />
    </div> :
    <div dangerouslySetInnerHTML={{ __html: data }}></div> }
  </>
  );
};

export default CmsData;
