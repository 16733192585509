
import { Redirect, Route } from 'react-router-dom';

import Layout from "../pages/layout/Layout";

function Public({component: Component,...rest}){
    return <Route {...rest} render={(props) =>(
         <Component {...props} />
    )} />
}

export default Public;
