import React from "react";


import { BrowserRouter as Router, Switch, Route,Redirect } from "react-router-dom";

import Resetpassword from "../pages/login/Resetpassword";
import ForgotResetPassword from "../pages/login/ForgotResetPassword";
import MailVerification from "../pages/login/MailVerification";
import Deeplink from "../pages/deeplink/Deeplink";

import CmsData from "../pages/cms/CmsData";

import Public from "./Public";

import Layout from "../pages/layout/Layout";
const webpage = () => {
  return (
    <Router>
      <Switch>

        <Public exact component={Resetpassword} path="/user/reset-password" />
        <Public
          exact
          component={ForgotResetPassword}
          path="/reset-password/token/:token"
        />
         <Public
          exact
          component={MailVerification}
          path="/verfiy/email/:token"
        />
        <Public exact component={Deeplink} path="/invite-user/share" />

        <Layout>
            <Public exact component={CmsData} path="/cms/:type"  />
        </Layout>


        <Route path="*" component={()=>{ return <div>Not Found</div> }}  ></Route>
      </Switch>
    </Router>
  );
};

export default webpage;
