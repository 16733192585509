import React from "react";
import Logo from "../../assets/images/login/Logo.svg";


<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
</style>


function Layout(props) {
  return (
    <React.Fragment>
       <div  style={{"max-width": "1200px","width": "100%","margin": "0 auto","padding": "0 15px" }}>
          <div style={{ "width": "100%","margin":"10px 0 20px","display":"flex","justify-content":"center" }}>
              <div style={{ "width":"150px","padding-bottom":"3px","border-bottom":"2px solid #ff781c" }}>
                  <a href="javascript:void(0)"><img style={{ "width":"100%","max-width":"100%" }} src={Logo}
                          alt="Meerkat Logo" /></a>
              </div>
          </div>
              {props.children}
       </div>
    </React.Fragment>
  );
}


export default Layout;
