const Deeplinks = ()=>{
	var timeout = null;
    const deepLink =  (options)=>{
      var fallback = options.fallback || '';
      var url = options.url || '';
      var iosLink = options.iosLink;
      var iosStoreLink = options.ios_store_link;
      var androidPackageName = options.android_package_name;
      var playStoreLink =
        'https://play.google.com/store/apps/details?id=' + androidPackageName;
      var ua = window.navigator.userAgent;

      // split the first :// from the url string
      var split = url.split(/:\/\/(.+)/);
      var scheme = split[0];
      var path = split[1] || '';
      var urls = {
        deepLink: url,
        iosLink: iosLink,
        iosStoreLink: iosStoreLink,
        android_intent:
          'intent://' +
          path +
          '#Intent;scheme=' +
          scheme +
          ';package=' +
          androidPackageName +
          ';end;',
        playStoreLink: playStoreLink,
        fallback: fallback
      };
      console.log(ua)
      var isMobile = {
        android: function () {
          return /android/i.test(ua);
        },
        ios: function () {
          return /iPhone|iPad|iPod/i.test(ua);
        }
      };

      // fallback to the application store on mobile devices
      if (isMobile.ios() && urls.iosLink && urls.iosStoreLink) {
        iosLaunch();
      } else if (isMobile.android() && androidPackageName) {
        androidLaunch();
      } else {
        // alert('Please open in mobile.')

        //return false;
        window.location = urls.fallback;
      }
      const launchWekitApproach = (url, fallback)=>{
        // alert(url);
        console.log(urls)
        //window.location = url;
        document.getElementById("l").src = url;
        setTimeout(function () {
          document.location = fallback;
        }, 3000);
      }
const launchIframeApproach = (url, fallback)=>{
clearTimeout(timeout);
    timeout = null;

       var iframe = document.createElement('iframe');
        iframe.style.border = 'none';
        iframe.style.width = '1px';
        iframe.style.height = '1px';
//        iframe.src = url;
//         iframe.onload = function () {
          window.location.replace(url); //"https://apps.apple.com/in/app/virdrobe/id1211106688";
  //      };
       iframe.src = url;

        window.onload = function () {
          document.body.appendChild(iframe);
//          setTimeout(function () {
           // alert("settimeout ");
  //              window.location = fallback;
    //      },3000);
        };

  timeout= setTimeout(function () {
           // alert("settimeout ");
                window.location.href = fallback;
          }, 5000);
      }


      const iosLaunch = ()=>{
        console.log('iiiiiiiiiiiiiioooooooooooossssssssssssssssss' + urls)
        // chrome and safari on ios >= 9 don't allow the iframe approach
        if (
          ua.match(/CriOS/) ||
          (ua.match(/Safari/) && ua.match(/Version\/(9|10|11)/))
        ) {
          launchWekitApproach(urls.iosLink, urls.iosStoreLink || urls.fallback);
        } else {
          launchIframeApproach(urls.iosLink, urls.iosStoreLink || urls.fallback);
        }
      }

      const androidLaunch = ()=>{
        // alert(urls);
        if (ua.match(/Chrome/)) {
          document.location = urls.android_intent;
        } else if (ua.match(/Firefox/)) {
          launchWekitApproach(urls.deepLink, urls.playStoreLink || urls.fallback);
        } else {
          launchIframeApproach(url, urls.playStoreLink || urls.fallback);
        }
      }
    }
     var options = {
      fallback: process.env.REACT_APP_WEB_BASE_URL,
      url: process.env.REACT_APP_WEB_BASE_URL,
      iosLink: process.env.REACT_APP_IOS_LINK,
      ios_store_link: process.env.REACT_APP_IOS_STORE_LINK,
      android_package_name:process.env.REACT_APP_ANDROID_PACAKGE_NAME
    }
    deepLink(options);

	return (
		<div>
			<iframe id="l" width="1" height="1" style={{ "visibility":"hidden" }}></iframe>
		</div>
	)
}

export default Deeplinks;
