import React, { useState } from "react";
import "../../assets/css/login.css";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import { useHistory,useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import axiosConfig from '../../config/Public'
function Resetpassword() {
  document.title="Reset Password";
  var states = {
    input: {},
    errors: {},
  };
  const [state, setValue] = useState(states);
  const [showPassword, setShowPassword] = useState(false);
  const [showcPassword, setShowcPassword] = useState(false);
  const [loader, setLoader] = useState(false);

  const [error, setShowError] = useState("");

  const location = useLocation();
  const history = useHistory();
  let credentials = location.state.input;
  credentials.newPassword = state.input.password;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate(e)) {
      setLoader(true);
      axiosConfig
        .post("/admin/login", credentials)
        .then((response) => {
          setLoader(false);
          if (response.data.statusCode == 200) {
            //205
            history.push("/dashboard");
          } else {
            history.push("/login");
          }
        })
        .catch((err) => {
          setLoader(false);
          setShowError(err.response.data.message);
        });
    } else {
      console.log("");
    }
  };

  const handleChange = (e) => {
    let input = state.input;
    input[e.target.name] = e.target.value;
    setValue((prevState) => ({ ...prevState, input: input }));
  };

  const validate = (e) => {
    let errors = {};
    errors["password"] = "";
    errors["cpassword"] = "";
    let isValid = true;
    if (state.input.password == undefined || state.input.password == "") {
      isValid = false;
      errors["password"] = "Please enter your password.";
    }
    if (state.input.cpassword == undefined || state.input.cpassword == "") {
      isValid = false;
      errors["cpassword"] = "Please enter your confirm password.";
    }
    if (state.input.password != state.input.cpassword) {
      isValid = false;
      errors["passwordNotMatch"] =
        "Your password does not match with confirm password";
    }
    if (state.input.password.length < 5) {
      isValid = false;
      errors["passwordLengthErr"] = "Password must be at least 6 character.";
    }

    setValue((prevState) => ({ ...prevState, errors: errors }));
    return isValid;
  };

  const handleTogglePassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };
  const handleTogglecPassword = () => {
    setShowcPassword((showcPassword) => !showcPassword);
  };

  return (
    <div className="accountWrapper">
     
      <div className="right-section">
        <div className="right-inner">
          <h1 className="page-title bottom-spacing">Reset Password</h1>
          <div className="form-wrapper top-spacing">
            <form onSubmit={handleSubmit}>
              <div className="password-row">
                <div className="form-group">
                  <label>*New Password</label>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    onChange={handleChange}
                    value={states.input.password}
                    placeholder="Enter Your New Password"
                  />
                  <div className="eye-icon">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </div>
                </div>
                <span className="error-msg">{state.errors.password}</span>
              </div>
              <div className="password-row top-spacing">
                <div className="form-group">
                  <label>*Confirm Password</label>
                  <TextField
                    type={showcPassword ? "text" : "password"}
                    id="cpassword"
                    name="cpassword"
                    onChange={handleChange}
                    value={states.input.cpassword}
                    placeholder="Confirm Your New Password"
                  />
                  <div className="eye-icon">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglecPassword}
                    >
                      {showcPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </div>
                  <span className="error-msg">{state.errors.cpassword}</span>
                </div>
              </div>
              {state.errors.passwordNotMatch ? (
                <span className="error-msg">
                  {state.errors.passwordNotMatch}
                </span>
              ) : (
                ""
              )}
              {error ? <span className="error-msg">{error}</span> : ""}
              {state.errors.passwordLengthErr ? (
                <span className="error-msg">
                  {state.errors.passwordLengthErr}
                </span>
              ) : (
                ""
              )}
              <div className="btn-wrap reset-btn">
                <Button variant="contained" type="submit" disabled={loader}>
                  {loader && <CircularProgress size={24} />}Set new password
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resetpassword;
