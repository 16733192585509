import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosPublic from "../../config/Public";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function MailVerification() {
  document.title="Email verification";
  const queryParam = useParams();
  let token = queryParam.token;

  const [displayMsg, setMsg] = useState({"status":false,"msg":"Please wait... email is being verified."});

  useEffect(() => {
    setMsg((pre)=>({...pre,"status":true}));

    axiosPublic
      .post("/auth/verify-email-token", { token: token })
      .then((response) => {
        setMsg({"status":false,"msg":response.data.message});
      })
      .catch((err) => {
        setMsg({"status":false,"msg":err.response.data.message});
      });
  }, []);

  return <>
    { displayMsg.status && <Box sx={{ display: 'flex',alignItem:"center",justifyContent:"center",height:"10vh",margin:"20px" }}>
      <CircularProgress style={{ color: 'green' }} />
    </Box> }
  <h1 style={{"fontSize": "28px","margin": "15px","color": "green"}} ><center> { displayMsg.msg} </center></h1>
  </>;
}

export default MailVerification;
