
import Webpage from './Route/Index';

function App() {
  return (
        <div className="App">
            <Webpage />
        </div>
    
  );
}

export default App;
